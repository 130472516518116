<template>
  <div class="search-form skeleton" />
</template>

<style scoped lang="scss">
.search-form {
  position: absolute;
  top: 0;
  left: 0;

  width: 27.5rem;
  height: 3.375rem;
  background-color: getColor('highlight-50');
  border: 1px solid getColor('primary-500');

  &.no-opacity {
    opacity: 0;
    z-index: -1;
  }
}

@keyframes skeleton-loading {
  0% {
    background-color: getColor('highlight-50');
  }

  100% {
    background-color: getColor('highlight-100');
  }
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}
</style>
